<template>
  <div class="online-setting fd-w-full fd-h-full fd-bg-white fd-px-4 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t("secondSidebar['Domain setting']") }}</span>
    </div>
    <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-px-6">
      <span class="fd-block fd-w-1/3 fd-text-sm fd-font-bold fd-text-theme-6">{{ $t('global.public') }}</span>
      <div
        class="fd-w-1/3 fd-border fd-border-theme-10 fd-rounded-lg fd-px-3 fd-py-3 fd-flex fd-items-center fd-justify-between fd-mr-4 fd-cursor-pointer"
        @click="public = true"
      >
        <span>{{ $t('global.on') }}</span>
        <span
          class="fd-block fd-w-6 fd-h-6 fd-rounded-full fd-border fd-border-theme-10 fd-overflow-hidden fd-flex fd-items-center fd-justify-center"
          :class="[public ? 'fd-bg-theme-4' : '']"
        >
          <span v-if="public" class="fd-block fd-w-2 fd-h-2 fd-rounded-full fd-bg-white"></span>
        </span>
      </div>

      <div
        class="fd-w-1/3 fd-border fd-border-theme-10 fd-rounded-lg fd-px-3 fd-py-3 fd-flex fd-items-center fd-justify-between fd-cursor-pointer"
        @click="public = false"
      >
        <span>{{ $t('global.off') }}</span>
        <span
          class="fd-block fd-w-6 fd-h-6 fd-rounded-full fd-border fd-border-theme-10 fd-overflow-hidden fd-flex fd-items-center fd-justify-center"
          :class="[!public ? 'fd-bg-theme-4' : '']"
        >
          <span v-if="!public" class="fd-block fd-w-2 fd-h-2 fd-rounded-full fd-bg-white"></span>
        </span>
      </div>
    </div>
    <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-px-6 fd-mt-6">
      <label for="slug" class="fd-block fd-w-1/2 fd-text-sm fd-font-bold fd-text-theme-6">
        {{ $t('global.slug') }}
      </label>
      <b-form-input
        id="slug"
        class="fd-w-full"
        placeholder="Slug"
        v-model="slug"
      />
    </div>
    <div class="fd-w-full fd-flex fd-items-center fd-justify-between fd-px-6 fd-mt-6">
      <label for="domain" class="fd-block fd-w-1/2 fd-text-sm fd-font-bold fd-text-theme-6">
        {{ $t('global.domain') }}
      </label>
      <b-form-input
        id="domain"
        class="fd-w-full"
        :placeholder="$t('global.domain')"
        v-model="domain"
      />
    </div>
    <div class="fd-w-full fd-flex fd-items-start fd-justify-end fd-mt-14 fd-pr-6">
      <b-button
        @click="save" variant="primary"
        style="padding-left: 35px !important; padding-right: 35px !important;"
      >
        {{ $t('global.save') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BTabs, BTab, BFormGroup, BFormTextarea, BButton, BToast } from 'bootstrap-vue'
export default {
  name: "OnlineSetting",
  components: {BFormGroup, BFormInput, BFormTextarea, BTabs, BTab, BButton, BToast},
  data() {
    return {
      validated: false,
      public: false,
      slug: '',
      domain: ''
    }
  },
  created() {
    this.$store.dispatch('project/getOnlineSetting', this.$route.params.id)
      .then(() => {
        this.dataPreparation()
      })
  },
  methods: {
    async save() {
      this.$store.dispatch('project/updateOnlineSetting', {
        id: this.$route.params.id,
        data: {
          public: this.public,
          slug: this.slug,
          language: this.languages
        }
      })
    },
    dataPreparation() {
      const data = this.$store.getters["project/onlineSetting"]
      this.public = data.public
      this.slug = data.slug
      this.domain = data.domain
    }
  }
}
</script>

<style lang="scss" scoped>
.online-setting {
  height: auto !important;
  min-height: 100% !important;

  ::v-deep .tabs {
    width: 100%;
    height: 100% !important;
    min-height: 100% !important;
  }

  ::v-deep .tabs {
    .nav-tabs {
      margin-bottom: 0 !important;

      .active.nav-link {
        &:after {
          bottom: auto !important;
          top: 0 !important;
          height: 6px !important;
        }

        background: #FBFBFB !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border: 1px solid #E6E7E6 !important;
        border-bottom: none !important;
      }
    }

    .tab-content {
      padding-right: 30px;
      padding-left: 30px;
      padding-bottom: 30px;
      background: #FBFBFB !important;
      border: 1px solid #E6E7E6 !important;
    }
  }
}
</style>